import React,{useRef,useState,useContext,useMemo} from 'react';
import ReactQuill,{Quill} from "react-quill";
import {useParams,useNavigate} from 'react-router-dom';
import { UserContext } from '../UserProvider';
import axios from 'axios';
import Loading from '../component/Loading.js';
import Nav from '../component/Nav.js';
import './Posting.css';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';
Quill.register('modules/ImageResize', ImageResize);

function Posting(){
	const quillRef = useRef(null);
	const {id}=useParams();
	const history = useNavigate();
	const {user} = useContext(UserContext);
	const [content, setContent] = useState(null);
	const [title,setTitle]=useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const toolbarOptions = [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		[{ 'font': [] }],
		[{'size':['small','normal','large','huge']}],
		['bold', 'italic', 'underline', 'strike'],
		['link', 'image'],
		[{ 'list': 'ordered'}, { 'list': 'bullet' }],
		[{ 'script': 'sub'}, { 'script': 'super' }],      // text direction
		[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
		[{ 'align': [] }],
	];
	const imageHandler = async () => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		input.addEventListener('change', async () => {
			const file = input.files[0];
			const formData = new FormData();
			formData.append('image', file);
			try {
				await axios.post('/imgapi',formData,{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					const imgUrl = res.data.imgUrl;
					const editor = quillRef.current.getEditor();
					const range = editor.getSelection();
					editor.insertEmbed(range.index, 'image', imgUrl);
					editor.setSelection(range.index + 1);
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
				});
			} catch (error) {
				console.log(error);
			}
		});
	};
	const modules=useMemo(
		()=>({
			toolbar:{container:toolbarOptions,handlers:{image:imageHandler}},
			ImageResize: {
				parchment: Quill.import('parchment')
			}
		}),[]);
	const createposts=()=>{
		if(user.isLogin){
			if(title!=='' && content!==''){
				setIsLoading(true);
				axios.post('/posts',{user_id:user.userid,user_name:user.username,title:title,content:content, type:id}, { withCredentials: true})
				.then(response => {
					window.alert(response.data.alert);
					setIsLoading(false);
					history('/board/1');
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
				});
			}
			else{
				window.alert('내용을 전부 입력해주세요')
				history(`/posting/${id}`)
			}
		}
		else{
			window.alert('로그인 후 이용해 주세요.')
			history('/login')
		}
	}
	return(
		<div>
			{isLoading?<Loading/>:
			<div>
				 <Nav/>
				 <div className='brd'>
					 <div className='brd_ct'>
						 <div className='txt_tt'>게시물 작성</div>
						 <form onSubmit={createposts}>
						 	<div className='tt_cts'>제목 : <input placeholder='제목을 입력하세요' onChange={(e)=>setTitle(e.target.value)} className='tt_input' required/></div>
						 	<ReactQuill ref={quillRef} modules={modules} className='txt_inputs' theme="snow" onChange={setContent} placeholder='내용을 입력하세요'/>
						 	<div className='edits_tt'><button className='txt_btns' type='submit'>등록</button></div>
						</form>
					 </div>
				</div>
			 </div>}
		</div>
	);
}

export default Posting;