import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from "../component/Loading.js";
import { UserContext } from "../UserProvider";
import "./User.css";
import Footer from "../component/Footer";
import Solved from "../component/Solved.js";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";

function User() {
  const popupRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [click, setClick] = useState(false);
  const [username, setUsername] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [radardata, setRadardata] = useState({});
  const [radardata2, setRadardata2] = useState({});
  const [choose, setChoose] = useState(2);
  const [maxVal, setMaxVal] = useState({});
  const [subData, setSubData] = useState({});
  const [math, setMath] = useState([]);
  const [english, setEnglish] = useState([]);
  const [korean, setKorean] = useState([]);
  const [popVal, setPopVal] = useState({});
  const [isPop, setIsPop] = useState(false);

  const [hist, setHist] = useState([]);
  const history = useNavigate();
  const types = {
    "1": "풀모",
    "2": "하프",
    "3": "킬러",
    "4": "어삼쉬사",
    "5": "하프",
    "6": "빈순삽",
    "7": "최고난도",
    "8": "시간단축",
    "9": "풀모",
    "10": "매3비",
    "11": "매3문",
    "12": "매3화작",
    "13": "풀모",
  };
  const select = {
    m1: "수1",
    m2: "수2",
    cal: "미적",
    prob: "확통",
    vec: "기하",
    media: "언매",
    talk: "화작",
  };
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  const categoryChoose = (val, types) => {
    if (types === 2) {
      const temp = [
        "지수와 로그",
        "지수함수 로그함수",
        "지수로그 부등식",
        "삼각법",
        "삼각함수",
        "사인법칙 코사인법칙",
        "등차수열 등비수열",
        "수열의 합",
        "수학적 귀납법",
        "함수의 극한",
        "수2 미분법",
        "수2 도함수 활용",
        "수2 적분법",
        "수2 정적분 활용",
        "수열의 극한",
        "미적 미분법",
        "미적 도함수 활용",
        "미적 적분법",
        "미적 정적분 활용",
        "경우의 수",
        "확률",
        "통계",
        "기하",
      ];
      return temp[Number(val) - 1];
    } else if (types === 3) {
      const temp = {
        "18": "글의 목적",
        "19": "심경 변화",
        "20": "필자의 주장",
        "21": "밑줄의 의미",
        "22": "글의 요지",
        "23": "글의 주제",
        "24": "글의 제목",
        "25": "도표",
        "26": "내용 일치",
        "27": "포스터",
        "29": "어법",
        "30": "낱말의 쓰임",
        "31": "빈칸 2점",
        "33": "빈칸 3점",
        "35": "글의 흐름",
        "36": "순서",
        "37": "순서 3점",
        "38": "삽입",
        "39": "삽입 3점",
        "40": "긴글 요약",
        "41": "중간글",
        "43": "긴글",
      };
      return temp[val];
    } else if (types === 1) {
      const temp = [
        "독서론",
        "인문/예술",
        "법/경제",
        "과학/기술",
        "독서 융합",
        "현대시",
        "고전시가",
        "현대소설",
        "고전산문",
        "극/수필",
        "문학 융합",
        "매체",
        "언어",
        "화법",
        "작문",
        "화법 작문 융합",
      ];
      return temp[Number(val) - 1];
    } else if (types === 4) {
      const temp = ["선사시대", "~조선", "일제강점기", "근현대"];
      return temp[Number(val) - 1];
    } else {
      return 0;
    }
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPop(false);
      setPopVal({});
    }
  };
  useEffect(
    () => {
      if (isPop) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      // Clean up event listener on unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [isPop]
  );
  useEffect(() => {
    var temp4={};
    axios
      .get("/getmax")
      .then((response) => {
        temp4={...response.data.maxs};
        setMaxVal(response.data.maxs);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/getsolved/1")
      .then((response) => {
        var sum = 0;
        var tot = 0;
        const ctg = {};
        response.data.solved.forEach((item) => {
          if (item.answer !== "") {
            const scores = item.correct.split(",");
            tot += item.problems.length;
            sum += item.score;
            item.category.split(",").forEach((temp, idx) => {
              if (!ctg[temp]) {
                ctg[temp] = { 'sum': 0, 'tot': 0 };
              }
              if (scores[idx] === "1") {
                ctg[temp]['sum'] += 1;
              }
              ctg[temp]['tot'] += 1;
            });
          }
        });
        const temp2 = [];
        const temp3 = [];
        Object.keys(ctg).forEach((item) => {
          temp2.push({
            category: categoryChoose(item, 2),
            A: ((ctg[item]['sum'] / ctg[item]['tot']) * 100).toFixed(1),
            fullMark: 100,
          });
          temp3.push({
            category: categoryChoose(item, 2),
            A: ((ctg[item]['tot'] / temp4['prb'][item]) * 100).toFixed(1),
            fullMark: 100,
          });
        });
        setRadardata((prevState)=>({ ...prevState, math: temp2 }));
        setRadardata2((prevState)=>({ ...prevState, math: temp3 }));
        setSubData((prevState) => ({
          ...prevState,
          math: { sum: sum, total: tot, category: ctg },
        }));
        setMath(response.data.solved.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/getsolved/5")
      .then((response) => {
        var sum = 0;
        var tot = 0;
        const ctg = {};
        response.data.solved.forEach((item) => {
          if (item.answer !== "") {
            const scores = item.correct.split(",");
            tot += item.problems.length;
            sum += item.score;
            item.category.split(",").forEach((temp, idx) => {
              if (!ctg[temp]) {
                ctg[temp] = { 'sum': 0, 'tot': 0 };
              }
              if (scores[idx] === "1") {
                ctg[temp]['sum'] += 1;
              }
              ctg[temp]['tot'] += 1;
            });
          }
        });
        const temp2 = [];
        const temp3 = [];
        Object.keys(ctg).forEach((item) => {
          temp2.push({
            category: categoryChoose(item, 3),
            A: ((ctg[item]['sum'] / ctg[item]['tot']) * 100).toFixed(1),
            fullMark: 100,
          });
          temp3.push({
            category: categoryChoose(item, 3),
            A: ((ctg[item]['tot'] / temp4['eng'][item]) * 100).toFixed(1),
            fullMark: 100,
          });
        });
        setRadardata(prevState=>({ ...prevState, english: temp2 }));
        setRadardata2(prevState=>({ ...prevState, english: temp3 }));
        setSubData((prevState) => ({
          ...prevState,
          english: { sum: sum, total: tot, category: ctg },
        }));
        setEnglish(response.data.solved.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/getsolved/9")
      .then((response) => {
        var sum = 0;
        var tot = 0;
        const ctg = {};
        response.data.solved.forEach((item) => {
          if (item.answer !== "") {
            const scores = item.correct.split(",");
            tot += item.problems.length;
            sum += item.score;
            item.category.split(",").forEach((temp, idx) => {
              if (!ctg[temp]) {
                ctg[temp] = { 'sum': 0, 'tot': 0 };
              }
              if (scores[idx] === "1") {
                ctg[temp]['sum'] += 1;
              }
              ctg[temp]['tot'] += 1;
            });
          }
        });
        const temp2 = [];
        const temp3 = [];
        Object.keys(ctg).forEach((item) => {
          temp2.push({
            category: categoryChoose(item, 1),
            A: ((ctg[item]['sum'] / ctg[item]['tot']) * 100).toFixed(1),
            fullMark: 100,
          });
          temp3.push({
            category: categoryChoose(item, 1),
            A: ((ctg[item]['tot'] / temp4['kor'][item]) * 100).toFixed(1),
            fullMark: 100,
          });
        });
        setRadardata(prevState=>({ ...prevState, korean: temp2 }));
        setRadardata2(prevState=>({ ...prevState, korean: temp3 }));
        setSubData((prevState) => ({
          ...prevState,
          korean: { sum: sum, total: tot, category: ctg },
        }));
        setKorean(response.data.solved.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/getsolved/13")
      .then((response) => {
        var sum = 0;
        var tot = 0;
        const ctg = {};
        response.data.solved.forEach((item) => {
          if (item.answer !== "") {
            const scores = item.correct.split(",");
            tot += item.problems.length;
            sum += item.score;
            item.category.split(",").forEach((temp, idx) => {
              if (!ctg[temp]) {
                ctg[temp] = { 'sum': 0, 'tot': 0 };
              }
              if (scores[idx] === "1") {
                ctg[temp]['sum'] += 1;
              }
              ctg[temp]['tot'] += 1;
            });
          }
        });
        const temp2 = [];
        const temp3 = [];
        Object.keys(ctg).forEach((item) => {
          temp2.push({
            category: categoryChoose(item, 4),
            A: ((ctg[item]['sum'] / ctg[item]['tot']) * 100).toFixed(1),
            fullMark: 100,
          });
          temp3.push({
            category: categoryChoose(item, 4),
            A: ((ctg[item]['tot'] / temp4['history'][item]) * 100).toFixed(1),
            fullMark: 100,
          });
        });
        setRadardata(prevState=>({ ...prevState, history: temp2 }));
        setRadardata2(prevState=>({ ...prevState, history: temp3 }));
        setSubData((prevState) => ({
          ...prevState,
          history: { sum: sum, total: tot, category: ctg },
        }));
        setHist(response.data.solved.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
      setIsLoading(false);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch("/changeuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(username),
      });
      if (response.ok) {
        const responseData = await response.json();
        window.alert(responseData.alert);
        setIsLoading(false);
        setClick(false);
        setUser({ ...user, username: username });
      } else {
        history("/error");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const Clearprb = async () => {
    let isClear = window.confirm("정말 초기화 하시겠습니까?");
    if (isClear) {
      try {
        setIsLoading(true);
        const response = await fetch("/clearprb", {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setIsLoading(false);
          window.alert(data.alert);
          setMath([]);
          setEnglish([]);
          setKorean([]);
          history("/userinfo");
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };
  const Deleteuser = async () => {
    let isDelete = window.confirm("정말 삭제하시겠습니까?");
    if (isDelete) {
      if (isDelete) {
        try {
          setIsLoading(true);
          const response = await fetch("/deleteuser", {
            method: "POST",
          });
          if (response.ok) {
            const data = await response.json();
            setIsLoading(false);
            window.alert(data.alert);
            setUser({});
            history("/");
          } else {
            history("/error");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }
    }
  };
  const RegenaratePrb = async (prbjson) => {
    try {
      setIsLoading(true);
      const response = await fetch("/regenarateprb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prbjson),
      });
      if (response.ok) {
        if (response.headers.get("Content-Type") === "application/pdf") {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "problems.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
          history("/userinfo");
        } else {
          const data = await response.json();
          window.alert(data.alert);
          setIsLoading(false);
          history("/");
        }
      } else {
        history("/error");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const CorrectPrb = (key) => {
    if (key.answer) {
      if (
        window.confirm(
          "다시 풀면 기존의 내 데이터가 변동될 수 있습니다.\n그래도 다시 푸시겠습니까?"
        )
      ) {
        history(`/correct/${key.id}`);
      }
    } else {
      history(`/correct/${key.id}`);
    }
  };
  const deletePrb = async (id, val) => {
    if (window.confirm("정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다.")) {
      try {
        setIsLoading(true);
        const response = await fetch("/deleteprb", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        });
        if (response.ok) {
          const types = Number(val);
          if (types >= 1 && types <= 4) {
            setMath(math.filter((item) => item.id !== id));
          } else if (types >= 5 && types <= 8) {
            setEnglish(english.filter((item) => item.id !== id));
          } else if (types >= 9 && types <= 12) {
            setKorean(korean.filter((item) => item.id !== id));
          } else if (types === 13) {
            setHist(hist.filter((item) => item.id !== id));
          }
          window.alert("삭제 되었습니다.");
          setIsLoading(false);
          history("/userinfo");
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };
  const handleClick = () => {
    setClick(click ? false : true);
    setUsername(user.username);
  };

  useEffect(
    () => {
      if (popVal && popVal.id) {
        setIsPop(true);
      }
    },
    [popVal]
  );
  const popUp = (key) => {
    if (key && key.answer) {
      setPopVal(key);
    }
  };
  useEffect(
    () => {
      document.body.style.overflow = isPop ? "hidden" : "auto";
      return () => {
        document.body.style.overflow = "auto";
      };
    },
    [isPop]
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="user_ct">
          {isPop &&
            popVal && (
              <Solved
                popVal={popVal}
                popupRef={popupRef}
              />
            )}

          <div className="nv">
            <Link to="/" className="nv_lg">
              기출조각
            </Link>
          </div>
          {click ? (
            <form className="ui_tct" onSubmit={handleSubmit}>
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">닉네임 : </div>
                  <input onChange={setUsername} name="name" value={username} />
                </div>
                <button
                  className="ui_chg"
                  onClick={() => setClick(click ? false : true)}
                >
                  수정
                </button>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">이메일 : </div>
                <div className="ui_txt">{user.useremail}</div>
              </div>
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">이용가능 :</div>
                  <div className="ui_txt">
                    {user.due_kor >= currentDate ? "국어 " : ""}
                    {user.due_math >= currentDate ? "수학 " : ""}
                    {user.due_eng >= currentDate ? "영어 " : ""}
                    {user.due_hist >= currentDate ? "한국사 " : ""}
                  </div>
                  <Link to="/pricing" className="credit_btn">
                    추가
                  </Link>
                </div>
                <button className="ui_chg_fn" type="submit">
                  완료
                </button>
              </div>
            </form>
          ) : (
            <div className="ui_tct">
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">닉네임 : </div>
                  <div className="ui_txt">{user.username}</div>
                </div>
                <button className="ui_chg" onClick={handleClick}>
                  수정
                </button>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">이메일 : </div>
                <div className="ui_txt">{user.useremail}</div>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">이용가능 :</div>
                <div className="ui_txt">
                  {user.due_kor >= currentDate ? "국어 " : ""}
                  {user.due_math >= currentDate ? "수학 " : ""}
                  {user.due_eng >= currentDate ? "영어 " : ""}
                  {user.due_hist >= currentDate ? "한국사 " : ""}
                </div>
                <Link to="/pricing" className="credit_btn">
                  추가
                </Link>
              </div>
            </div>
          )}
          <div className="usr_ct">
            <div className="usr_bx">
              <div className="bx_txt">과목별 분석</div>
              <div className="bx_select">
                <div
                  onClick={() => setChoose(1)}
                  style={{ color: choose === 1 ? "#333" : "#888",display:user.due_kor>=currentDate?'block':'none' }}
                >
                  국어
                </div>
                <div
                  onClick={() => setChoose(2)}
                  style={{ color: choose === 2 ? "#333" : "#888" ,display:user.due_math>=currentDate?'block':'none'}}
                >
                  수학
                </div>
                <div
                  onClick={() => setChoose(3)}
                  style={{ color: choose === 3 ? "#333" : "#888" ,display:user.due_eng>=currentDate?'block':'none'}}
                >
                  영어
                </div>
                <div
                  onClick={() => setChoose(4)}
                  style={{ color: choose === 4 ? "#333" : "#888" ,display:user.due_hist>=currentDate?'block':'none'}}
                >
                  한국사
                </div>
              </div>
              <div className="bx_report">
                {user.due_hist>=currentDate && choose === 4 && subData.history && (
                  <div className="report_ct">
                    <div className="crt_bx">
                      <div className="crt_sm_title">평균 정답률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.history.sum /
                                subData.history.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.history.sum /
                                subData.history.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.history.sum / subData.history.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crt_bx">
                      <div className="crt_sm_title">기출 정복률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.history.total /
                                maxVal.history.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.history.total /
                                maxVal.history.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.history.total / maxVal.history.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="category_crt_bx">
                      <div className="category_chart_bx">
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata.history}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 정답률</div>
                      </div>
                      <div>
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="80%"
                          width={300}
                          height={200}
                          data={radardata2.history}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 성취도</div>
                      </div>
                    </div>
                  </div>
                )}
                {user.due_kor>=currentDate && choose === 1 && subData.korean && (
                  <div className="report_ct">
                    <div className="crt_bx">
                      <div className="crt_sm_title">평균 정답률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.korean.sum /
                                subData.korean.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.korean.sum /
                                subData.korean.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.korean.sum / subData.korean.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crt_bx">
                      <div className="crt_sm_title">기출 정복률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.korean.total /
                                maxVal.kor.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.korean.total /
                                maxVal.kor.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.korean.total / maxVal.kor.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="category_crt_bx">
                      <div className="category_chart_bx">
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata.korean}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 정답률</div>
                      </div>
                      <div>
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata2.korean}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 성취도</div>
                      </div>
                    </div>
                  </div>
                )}
                {user.due_math>=currentDate && choose === 2 && subData.math &&(
                  <div className="report_ct">
                    <div className="crt_bx">
                      <div className="crt_sm_title">평균 정답률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.math.sum /
                                subData.math.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.math.sum /
                                subData.math.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.math.sum / subData.math.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crt_bx">
                      <div className="crt_sm_title">기출 정복률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.math.total /
                                maxVal.prb.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.math.total /
                                maxVal.prb.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.math.total / maxVal.prb.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="category_crt_bx">
                      <div className="category_chart_bx">
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata.math}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 정답률</div>
                      </div>
                      <div>
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata2.math}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 성취도</div>
                      </div>
                    </div>
                  </div>
                )}
                {user.due_eng>=currentDate && choose === 3 && subData.english && (
                  <div className="report_ct">
                    <div className="crt_bx">
                      <div className="crt_sm_title">평균 정답률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.english.sum /
                                subData.english.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.english.sum /
                                subData.english.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.english.sum / subData.english.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crt_bx">
                      <div className="crt_sm_title">기출 정복률</div>
                      <div style={{ position: "relative", top: "3px" }}>
                        <div
                          className="crt_gage"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            style={{
                              background: "#333",
                              width: `${(subData.english.total /
                                maxVal.eng.total) *
                                100}%`,
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="crt_gage">
                          <div
                            style={{
                              marginLeft: `${(subData.english.total /
                                maxVal.eng.total) *
                                100 -
                                5}%`,
                              fontSize: "12px",
                            }}
                          >
                            {(
                              (subData.english.total / maxVal.eng.total) *
                              100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="category_crt_bx">
                      <div className="category_chart_bx">
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata.english}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 정답률</div>
                      </div>
                      <div>
                        <RadarChart
                          cx="50%"
                          cy="50%"
                          outerRadius="50%"
                          width={300}
                          height={200}
                          data={radardata2.english}
                        >
                          <PolarGrid />
                          <Tooltip />
                          <PolarAngleAxis dataKey="category" fontSize={12} />
                          <PolarRadiusAxis />
                          <Radar
                            dataKey="A"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                        </RadarChart>
                        <div className="cg_chart_bx_tt">카테고리별 성취도</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="usr_ct">
            <div className="usr_bx">
              <div className="bx_txt">내가 푼 문제</div>
              <div className="bx_select">
                <div
                  onClick={() => setChoose(1)}
                  style={{ color: choose === 1 ? "#333" : "#888",display:user.due_kor>=currentDate?'block':'none' }}
                >
                  국어
                </div>
                <div
                  onClick={() => setChoose(2)}
                  style={{ color: choose === 2 ? "#333" : "#888" ,display:user.due_math>=currentDate?'block':'none'}}
                >
                  수학
                </div>
                <div
                  onClick={() => setChoose(3)}
                  style={{ color: choose === 3 ? "#333" : "#888" ,display:user.due_eng>=currentDate?'block':'none'}}
                >
                  영어
                </div>
                <div
                  onClick={() => setChoose(4)}
                  style={{ color: choose === 4 ? "#333" : "#888" ,display:user.due_hist>=currentDate?'block':'none'}}
                >
                  한국사
                </div>
              </div>
              <div className="bx_prb">
                {user.due_kor>=currentDate && choose === 1 &&
                  korean.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined && (
                        <div className="test"onClick={() => popUp(key)}>
                          <div className="prb_types">
                            {types[key.types]}
                            {key.types === "9" || key.types === "12"
                              ? `(${select[key.select_type]})`
                              : ""}
                          </div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div
                            className="solves"
                            style={{ opacity: key.answer ? "1" : "0.5" }}
                          >
                            {key.answer
                              ? key.score + "/" + key.problems.length
                              : key.problems.length}
                            문제
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="cor_prb"
                              onClick={() => CorrectPrb(key)}
                            >
                              {key.answer ? "다시풀기" : "채점하기"}
                              <img src="/img/correct.png" width="15px" alt="" />
                            </div>
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {user.due_math>=currentDate && choose === 2 &&
                  math.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined &&
                      key.select_type !== undefined && (
                        <div className="test"onClick={() => popUp(key)}>
                          <div className="prb_types">
                            {types[key.types]}({select[key.select_type]})
                          </div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div
                            className="solves"
                            style={{ opacity: key.answer ? "1" : "0.5" }}
                          >
                            {key.answer
                              ? key.score + "/" + key.problems.length
                              : key.problems.length}
                            문제
                          </div>

                          <div className="prb_btn_bx">
                            <div
                              className="cor_prb"
                              onClick={() => CorrectPrb(key)}
                            >
                              {key.answer ? "다시풀기" : "채점하기"}
                              <img src="/img/correct.png" width="15px" alt="" />
                            </div>
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {user.due_eng>=currentDate && choose === 3 &&
                  english.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined && (
                        <div className="test" onClick={() => popUp(key)}>
                          <div className="prb_types">{types[key.types]}</div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div
                            className="solves"
                            style={{ opacity: key.answer ? "1" : "0.5" }}
                          >
                            {key.answer ? key.score + "/" : ""}
                            {key.types === "5"
                              ? "28"
                              : key.types === "8"
                                ? "15"
                                : key.problems.length}
                            문제
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="cor_prb"
                              onClick={() => CorrectPrb(key)}
                            >
                              {key.answer ? "다시풀기" : "채점하기"}
                              <img src="/img/correct.png" width="15px" alt="" />
                            </div>
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {user.due_hist>=currentDate && choose === 4 &&
                  hist.map(
                    (key) =>
                      key.problems !== undefined && (
                        <div className="test" onClick={() => popUp(key)}>
                          <div className="prb_types">{types[key.types]}</div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div
                            className="solves"
                            style={{ opacity: key.answer ? "1" : "0.5" }}
                          >
                            {key.answer
                              ? key.score + "/" + key.problems.length
                              : key.problems.length}
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="cor_prb"
                              onClick={(e) => {
                                e.stopPropagation();
                                CorrectPrb(key);
                              }}
                            >
                              {key.answer ? "다시풀기" : "채점하기"}
                              <img src="/img/correct.png" width="15px" alt="" />
                            </div>
                            <div
                              className="down_prb"
                              onClick={(e) => {
                                e.stopPropagation();
                                RegenaratePrb(key);
                              }}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={(e) => {
                                e.stopPropagation();
                                deletePrb(key.id, key.types);
                              }}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="btn_cts">
            <div className="prb_reset" onClick={Clearprb}>
              문제 초기화
            </div>
            <div className="user_out" onClick={Deleteuser}>
              회원 탈퇴
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default User;
