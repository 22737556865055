import React, { useState, useContext,useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import Nav from "../component/Nav";
import "./Pricing.css";
import Popup from "../component/Popup";
import Footer from "../component/Footer";

function Pricing() {
  const [isPop,setIsPop]=useState(false);
  const popupRef=useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [goodsName, setGoodsName] = useState("");
  const [choose,setChoose]=useState([0,0,0,0]);
  const [price, setPrice] = useState(0);
  const { user } = useContext(UserContext);
  const [refund, setRefund] = useState(false);
  const history = useNavigate();
  const handlePrice = (type) => {
    if (user.isLogin) {
      if(type===2){
        setGoodsName("전과목 이용권");
        setPrice(15000);
        setChoose([1,1,1,1]);
        setIsOpen(true);
      }
      else{
        setIsOpen(true);
        setIsPop(false);
        setGoodsName("개별 이용권");
      }
    } else {
      window.alert("로그인 후 이용해 주세요.");
      history("/login");
    }
  };
  const handleClickOutside = (event) => {
    if (!isOpen && popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPop(false);
    }
  };
  useEffect(()=>{
    if(!isPop && !isOpen){
      setChoose([0,0,0,0]);
      setPrice(0);
    }
  },[isPop])
  useEffect(
    () => {
      if (isPop) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      // Clean up event listener on unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [isPop]
  );
  const collectChoose=(types)=>{
    const temp=[...choose];
    if(temp[types]===1){
      temp[types]=0;
      if(types===3){
        setPrice(price-1000);
      }
      else{
        setPrice(price-6000);
      }
    }
    else{
      if(types===3){
        setPrice(price+1000);
      }
      else{
        setPrice(price+6000);
      }
      temp[types]=1;
    }
    setChoose(temp);
  }
  return (
    <div>
      <div className="price_ct">
        <Nav />
        <div className="prc_ct">
          <div className="collect_sub" style={{display:isPop?'flex':'none'}}>
            <div className="collect_inner" ref={popupRef}>
              <div className="collect_bx">
                <div className="collect_chs" onClick={()=>collectChoose(0)}style={{border:choose[0]?'2px solid #333':'2px solid #ccc',color:choose[0]?'#333':'#ccc'}}>국어</div>
                <div className="collect_chs" onClick={()=>collectChoose(1)}style={{border:choose[1]?'2px solid #333':'2px solid #ccc',color:choose[1]?'#333':'#ccc'}}>수학</div>
                <div className="collect_chs" onClick={()=>collectChoose(2)}style={{border:choose[2]?'2px solid #333':'2px solid #ccc',color:choose[2]?'#333':'#ccc'}}>영어</div>
                <div className="collect_chs" onClick={()=>collectChoose(3)} style={{border:choose[3]?'2px solid #333':'2px solid #ccc',color:choose[3]?'#333':'#ccc'}}>한국사</div>
              </div>
              <div className="collect_res">
                <div className="collect_price">{price}원</div>
                <div className="collect_btn" onClick={()=>handlePrice(1)}>결제하기</div>
              </div>
            </div>
          </div>
          <div className="prc_bx" onClick={()=>setIsPop(true)}>
            <div className="pc_txt">1과목 이용</div>
            <div className="pc_txt">수능까지 무제한(~25.11.13)</div>
            <div className="pc_txt column1">&#8361;6,000</div>
            <div
              className="pc_btn"
            >
              →
            </div>
          </div>
          <div className="prc_bx" onClick={() => handlePrice(2)}>
            <div className="pc_txt">전과목</div>
            <div className="pc_txt">수능까지 무제한(~11.14)</div>
            <div className="pc_txt column1">
              <del className="pr_sale">&#8361;26,700</del>
              &#8361;15,000
              <div className="sale_per">56%할인</div>
            </div>
            <div
              className="pc_btn"
            >
              →
            </div>
          </div>
          <div style={{ color: "rgb(105,105,105)", fontSize: "12px" ,maxWidth:'90%'}}>
            ※과목별 이용권을 구매하시면 다음 학년도 수능날까지 무제한 이용이 가능합니다.
          </div>
          <div className="refund_intro column1">
            <div className="refund_txt" onClick={() => setRefund(!refund)}>
              환불 규정 안내 →
            </div>
            <div
              className="refund_ser"
              style={{ display: refund ? "block" : "none" }}
            >
              <p>
                1. 회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가
                안내하는 정책 및 방법에 따라 진행됩니다.
              </p>
              <p>2. 회사는 다음 각 호의 방식으로 환불을 진행합니다.</p>
              <p>
                연간(수능형) 결제 회원 : 수능형은 결제일로부터 수능일까지 월
                결제로 계산하여 하루라도 이용한 월은 제외하고 남은 일수를 전체
                일수로 나눈 비율만큼의 20%를 환불합니다.
              </p>
            </div>
          </div>
        </div>
        <Popup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          goodsName={goodsName}
          price={price}
          choose={choose}
        />
        <Footer/>
      </div>
    </div>
  );
}

export default Pricing;
