import React,{useState} from 'react';
import './Login.css';
import Loading from '../component/Loading.js';
import {useNavigate} from 'react-router-dom';

function Findid(){
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		useremail:undefined,
	});
	const history = useNavigate();

	const findidSubmit=async(e)=>{
		e.preventDefault();
		try{
			setIsLoading(true);
			const response = await fetch('/findid', {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			const responseData = await response.json();
			if (response.ok) {
				setIsLoading(false);
				window.alert(responseData.alert);
				history('/');
			} else{
				window.alert(responseData.alert);
				history('/')
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};
	const handleChange=(e)=>{
		setFormData({ ...formData, [e.target.name]: e.target.value});
	}
	return(
		<div className='lgin_ct'>
				{isLoading? <Loading/>:''}
				<form onSubmit={findidSubmit} className='lgin_form'>
					<div className='lgin_tt'>기출조각</div>
					<input type='email' name='useremail' placeholder='이메일' onChange={handleChange} className='lgin_txt' required></input>
					<button type='submit' className='lgin_btn'>아이디 찾기</button>
				</form>
		</div>
	);
}

export default Findid;