import React, { useState, useRef, useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../UserProvider";
import Nav from "../component/Nav";
import "../font/font-awesome/css/all.min.css";
import "./Main.css";
import Slideshow from "../component/SlideShow";
import Footer from "../component/Footer";
function Main() {
  const { user } = useContext(UserContext);
  const refs = useRef([]);
  const [txt1, setTxt1] = useState("None");
  const [txt2, setTxt2] = useState("None");
  const [txt3, setTxt3] = useState("None");
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // 애니메이션 후 더 이상 관찰하지 않음
          }
        });
      },
      {
        threshold: 1, // 요소가 50% 보일 때 실행
      }
    );
    refs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      refs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);
  function arcClick(idx) {
    if (idx === 1) {
      setTxt1(txt1 === "None" ? "block" : "None");
    } else if (idx === 2) {
      setTxt2(txt2 === "None" ? "block" : "None");
    } else if (idx === 3) {
      setTxt3(txt3 === "None" ? "block" : "None");
    }
  }
  return (
    <div className="tttt">
      <Nav/>
			{user.isLogin && <div className='prb_bt'>
				<div className="prb_bt_txt" >문제집</div>
        <div className='prb_go'>
					<div><Link to='/problem/9' className='prb_item' >국어</Link><div className='prb_item_txt'>#풀모 #매3비<br/>#매3문 #매3화작언매</div></div>
					<div><Link to='/problem/1' className='prb_item' >수학</Link><div className='prb_item_txt'>#풀모 #하프<br/>#킬러 #어삼쉬사</div></div>
					<div><Link to='/problem/5' className='prb_item' >영어</Link><div className='prb_item_txt'>#하프 #최고난도 #빈순삽 #시간단축</div></div>
					<div><Link to='/problem/13' className='prb_item' >한국사</Link><div className='prb_item_txt'>#풀모<br/>20문제</div></div>
				</div>
			</div>}
      {!user.isLogin &&<div className="hero">
        <Slideshow interval={7000}>
          <div className="slide1 row1">
            <div className="slide1_txt column1" style={{ gap: "10px" }}>
              <div>풀어야 하는 기출 문제. 쌓여만 가는 문제집.</div>
              <div style={{ fontSize: "16px", fontWeight: "500" }}>
                좀 더 간편하게 기출을 접하고 싶지 않으신가요?{" "}
              </div>
              <div style={{ fontSize: "16px", fontWeight: "500" }}>
                기출조각에서는 가능합니다!
              </div>
							<Link to='/login' className='slide_go'>시작하기</Link>
            </div>
            <div className="slide2_img">
              <img src="/img/slide2.png" alt="slide0" width="100%" />
            </div>
          </div>
          <div className="slide1 row1">
            <div className="slide1_txt column1">
              <div>실전과 100% 유사한 문제 유형</div>
              <div style={{ fontSize: "16px", fontWeight: "500" }}>
                수능 대비를 위한 완벽한 포맷을 지원합니다.
              </div>
							<Link to='/login' className='slide_go'>시작하기</Link>
            </div>
            <div className="slide1_img">
              <img src="/img/slide1.PNG" alt="slide1" width="100%" />
            </div>
          </div>

          <div className="slide2 row1">
            <div className="slide1_txt column1">
              <div>원하는 난이도에 맞는 시험지</div>
              <div style={{ fontSize: "16px", fontWeight: "500" }}>
                2점부터 킬러까지 기출조각에서 완벽 공부하세요.
              </div>
							<Link to='/login' className='slide_go'>시작하기</Link>
            </div>
            <div className="slide3_img">
              <img
                className="slide3_de"
                src="/img/slide3_1.PNG"
                alt="half"
                width="250px"
              />
              <img
                className="slide3_de"
                src="/img/slide3_2.PNG"
                alt="d3e4"
                width="250px"
              />
              <img
                className="slide3_de"
                src="/img/slide3_3.PNG"
                alt="killer"
                width="250px"
              />
            </div>
          </div>

          <div className="slide4 row1">
            <div className="slide1_txt column1">
              <div>3초만에 만드는 나만의 문제집</div>
              <div style={{ fontWeight: "400", fontSize: "16px" }}>
                쉽고 빠르게 문제를 만들어서 풀어보세요.
              </div>
							<Link to='/login' className='slide_go'>시작하기</Link>
            </div>
            <video
              loop
              muted
              autoPlay
              playsInline
              width="400px"
              style={{ border: "1px solid #888", borderRadius: "5px" }}
            >
              <source src="/img/slide4.mp4" type="video/mp4" />
            </video>
          </div>
        </Slideshow>
      </div>}
      {!user.isLogin && <div className="hero2 effect7" ref={(el) => (refs.current[0] = el)}>
        <div className="review_tt" >
          <div className="lt_desc" style={{ fontWeight: "800" }}>
            기출조각은 여러분의 이야기를 항상 듣습니다.
          </div>
          <div className="lt_desc_smal">
            편리한 공부를 위해 최선을 다하겠습니다.
          </div>
        </div>
        <div className="ds1" >
          <div className="review">
            <div className="review-content">
              <p>"정말 편리한 사이트예요! 문제 수준도 다양하고 좋아요."</p>
              <p>"시험 대비에 큰 도움이 됩니다. 자주 이용할게요!"</p>
              <p>"랜덤 문제 덕분에 실전 감각을 키우기 좋아요."</p>
              <p>"실제 시험과 유사한 시험지라 좋았습니다."</p>
              <p>"이용하기 쉽고, 내용도 충실해요."</p>
            </div>
          </div>
          <div className="review">
            <div className="review-content">
              <p>"문제의 범위가 넓어서 다양한 유형을 접할 수 있어요."</p>
              <p>"공부할 때마다 다른 문제라서 좋습니다."</p>
              <p>"문제가 매번 달라서 실전 연습에 좋아요."</p>
              <p>"문제 풀이 연습에 정말 좋습니다."</p>
              <p>"잘 썼습니다~"</p>
            </div>
          </div>
          <div className="review">
            <div className="review-content">
              <p>"정말 추천하는 사이트입니다."</p>
              <p>"연습 문제 풀기에 최고입니다."</p>
              <p>"효율적인 공부를 위한 최고의 사이트입니다."</p>
              <p>"앵간 개꿀이네요 ㅋㅋㅋ"</p>
              <p>"수능 직전에 한번 써볼게요"</p>
              <p>"마더텅 빨간책 온라인 버전"</p>
            </div>
          </div>
          <div className="review">
            <div className="review-content">
              <p>"기출조각, 정말 감사해요!"</p>
              <p>"정말 유익한 사이트입니다, 추천해요!"</p>
              <p>"문제 풀 때마다 새로운 느낌이에요."</p>
              <p>"디자인만 좀 개선하면 대박일듯"</p>
              <p>"개꿀"</p>
              <p>"해설 기능도 추가하면 좋을 것 같아요"</p>
            </div>
          </div>
        </div>
      </div>}
      {!user.isLogin &&<div className="last_text column1">
        <div className="row1 ds3 effect0" ref={(el) => (refs.current[1] = el)}>
          <div className="lt_title">WHY<br/>기출조각?</div>
          <div className="lt_desc">
            수능 고득점을 위해서{" "}
            <span style={{ fontWeight: "800" }}>기출은 필수</span>
            입니다. 그래서 기출문제집, ebs 등 수많은 문제를 풀지만 같은 형식에
            익숙해져 단순 반복이 되어 의미가 퇴색되기 마련입니다. 기출조각을
            통해{" "}
            <span style={{ fontWeight: "800" }}>
              본인이 필요한, 다양한 기출
            </span>
            을 제공하고 싶었습니다. 기출조각을 최대한 알차게 뽑아먹고
            고득점으로 앞으로의 삶에 날개를 다세요.
          </div>
        </div>
      </div>}
      <div className="faq column1">
        <div
          className="column1 ds4 effect5"
          ref={(el) => (refs.current[6] = el)}
        >
          <div className="faq_txt">TALK</div>
          <div className="arcodion">
            <div className="arc" onClick={() => arcClick(1)}>
              {txt1 === "None" ? "+" : "-"} 기출조각은 이렇습니다.
            </div>
            <div className="arc_txt" style={{ display: txt1 }}>
              기출조각은 수험생들을 위해 엄선한 평가원 기출 문제를 제공합니다.
              매번 풀던 기출을 색다르게 풀어보세요.
            </div>

            <div className="arc" onClick={() => arcClick(2)}>
              {txt2 === "None" ? "+" : "-"} 기출조각은 이렇게 사용합니다.
            </div>
            <div className="arc_txt" style={{ display: txt2 }}>
              각자 원하는 형태에 따라서 풀모의고사, 하프모의고사, 어삼쉬사, 킬러
              중 하나를 선택하고 체크 항목들을 선택하면 자동으로 문제가
              제공됩니다. 클릭 한번으로 다양한 문제를 제공합니다.
            </div>

            <div className="arc" onClick={() => arcClick(3)}>
              {txt3 === "None" ? "+" : "-"} 기출조각의 마음은 이렇습니다.
            </div>
            <div className="arc_txt" style={{ display: txt3 }}>
              기출조각은 제 수험 생활 경험을 토대로 만들어졌습니다. 기출이
              중요한건 알지만 개념이 부족해서 못 풀던 시절, 고난도 문제를 풀기엔
              실력이 부족해 미뤄뒀던 경험 등 제가 가졌던 불안과 낭비한 시간을
              여러분은 가지지 않았으면 좋겠다는 마음에서 시작했습니다. 부디
              기출조각으로 뽕을 뽑아서 원하는 대학, 진로에 이르시고 저희에게
              자랑해 주세요. 항상 응원하고 있습니다.
            </div>
          </div>
        </div>
      </div>
      <a name="help" href="#!" style={{textDecoration:'none'}}>
        <div
          className="column1 contact effect6"
          ref={(el) => (refs.current[7] = el)}
        >
          <div className="cont_txt" style={{color:"#333"}}>Contact</div>
          <form
            action="https://submit-form.com/DvEWWDiRX"
            className="column1 cont_form"
          >
            <input
              type="text"
              name="name"
              className="ipt"
              placeholder="Name"
              required
            />
            <input
              type="email"
              name="email"
              className="ipt"
              placeholder="Email"
              required
            />
            <input
              type="textarea"
              name="message"
              className="ipt"
              placeholder="Message"
              required
            />
            <button type="submit" className="send" style={{background:'#333'}}>
              Send
            </button>
          </form>
        </div>
      </a>
      <Footer/>
    </div>
  );
}

export default Main;
