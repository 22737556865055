import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../component/Nav";
import { UserContext } from "../UserProvider";
import "./Make.css";
import Dropdown from "../component/Dropdown";
import CheckboxGroup from "../component/CheckboxGroup";
import Loading from "../component/Loading";
function Make() {
  const history = useNavigate();
  const { user } = useContext(UserContext);
  const [loading, setIsLoading] = useState(false);
  const years1 = [
    2024,
    2023,
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006,
  ];
  const math_category = [
    "지수와 로그",
    "지수함수와 로그함수",
    "지수 부등식과 방정식",
    "삼각법",
    "삼각함수",
    "도형",
    "등차수열 등비수열",
    "수열의 합",
    "수열의 귀납적 정의",
    "함수의 극한",
    "수2-미분법",
    "수2-도함수 활용",
    "수2-적분법",
    "수2-정적분 활용",
    "미적-수열읙 극한",
    "미적-미분법",
    "미적-도함수 활용",
    "미적-적분법",
    "미적-정적분 활용",
    "경우의 수",
    "확률",
    "통계",
    "기하",
  ];
  const [formData, setFormData] = useState({
    start: 2018,
    end: 2024,
    counts: 5,
    difficulty: 0.2,
  });
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  const diff = {
    0.2: "기본",
    0.4: "개념",
    0.6: "심화",
    0.8: "킬러",
  };
  const descriptions = [
    "가장 쉬운 기본 문제",
    "개념 정리를 위한 쉬운 난이도의 문제",
    "개념을 완성을 위한 적정 난이도의 문제",
    "실력을 발전을 위한 고난도의 문제",
    "고득점을 위한 최고난도 문제",
  ];
  const [selectCategory, setSelectCategory] = useState([]);
  const [checkedItems, setCheckedItems] = useState({
    all: false,
    pyung: true,
    gyo: false,
    sag: false,
    pol: false,
  });
  const nameList = {
    all: "전체",
    pyung: "평가원",
    gyo: "교육청",
    sag: "사관학교",
    pol: "경찰대",
  };
  const [isClick, setIsClick] = useState(false);
  function dpnClick() {
    setIsClick(isClick ? false : true);
  }
  function keyClick(key) {
    if (!selectCategory.includes(key)) {
      setSelectCategory([...selectCategory, key]);
      console.log(selectCategory);
    }
  }
  const deleteCategory = (key) => {
    setSelectCategory(selectCategory.filter((selected) => selected !== key));
  };
  const handleChange = (names, values) => {
    setFormData({ ...formData, [names]: values });
  };
  if (formData.start > formData.end) {
    setFormData({ ...formData, start: 2018, end: 2024 });
  }
  const submitMake = async (e) => {
    e.preventDefault();
    if (selectCategory.length === 0) {
      window.alert("카테고리를 1개 이상 선택해 주세요.");
    } else if (
      !checkedItems.pyung &&
      !checkedItems.gyo &&
      !checkedItems.pol &&
      !checkedItems.sag
    ) {
      window.alert("출처를 1개 이상 선택해 주세요");
    } else if(user && user.due_math> currentDate) {
      try {
        setIsLoading(true);
        const response = await fetch("/diymake", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            category: selectCategory,
            select: checkedItems,
          }),
        });
        if (response.ok) {
          if (response.headers.get("Content-Type") === "application/pdf") {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement("a");
            a.href = url;
            a.download = "problems.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
            history("/done");
          } else {
            const data = await response.json();
            window.alert(data.alert);
            setIsLoading(false);
            history("/");
          }
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    } else{
      window.alert('로그인 및 이용권 구매 후 이용해 주세요.')
      history('/pricing')
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Nav />
          <div className="make_tct">
            <div className="make_ct">
              <div className="make_lst">
                <div className="make_title">문제수</div>
                <div className="make_counts">
                  <div>
                    <input
                      type="radio"
                      id="cal"
                      name="select"
                      value={formData.counts}
                      checked={formData.counts === 5}
                      onClick={() => handleChange("counts", 5)}
                    />
                    <label htmlFor="cal">5개</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="prob"
                      name="select"
                      value={formData.counts}
                      checked={formData.counts === 10}
                      onClick={() => handleChange("counts", 10)}
                    />
                    <label htmlFor="prob">10개</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="vec"
                      name="select"
                      value={formData.counts}
                      checked={formData.counts === 20}
                      onClick={() => handleChange("counts", 20)}
                    />
                    <label htmlFor="vec">20개</label>
                  </div>
                </div>
              </div>
              <div className="make_lst">
                <div className="make_title">난이도</div>
                <div className="make_diff">
                  <div className="range-slider">
                    <input
                      className="range-slider__range"
                      type="range"
                      min="0.2"
                      max="0.8"
                      step="0.2"
                      value={formData.difficulty}
                      onChange={(e) =>
                        handleChange("difficulty", e.target.value)
                      }
                    />
                    <span className="range-slider__value">
                      {diff[formData.difficulty]}
                    </span>
                  </div>
                  <div className="range_desc">
                    {descriptions[formData.difficulty]}
                  </div>
                </div>
              </div>
              <div className="make_lst">
                <div className="make_title">출제연도</div>
                <div className="make_year">
                  <Dropdown
                    items={years1}
                    val={formData.start}
                    valChange={setFormData}
                    name="start"
                    data={formData}
                  />
                  <Dropdown
                    items={years1}
                    val={formData.end}
                    valChange={setFormData}
                    name="end"
                    data={formData}
                  />
                </div>
              </div>
              <div className="make_lst">
                <div className="make_title">카테고리</div>
                <div className="make_category">
                  <div className="dpn">
                    <div className="cur" onClick={dpnClick}>
                      카테고리
                    </div>
                    <div className="ico" onClick={dpnClick} />
                    <div
                      className="cur_dpn"
                      style={{ display: isClick ? "block" : "None" }}
                    >
                      {math_category.map((year, idx) => (
                        <div
                          key={idx + 1}
                          className="dpn_key"
                          onClick={() => keyClick(idx + 1)}
                        >
                          {year}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="items_ct">
                    {selectCategory.map((item) => (
                      <div
                        className="make_items"
                        onClick={() => deleteCategory(item)}
                      >
                        {math_category[item - 1]}
                        <div className="make_delete">X</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="make_lst">
                <div className="make_title">출처</div>
                <div className="make_fr">
                  <CheckboxGroup
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    nameList={nameList}
                  />
                </div>
              </div>
              <div className="make_btn" onClick={submitMake}>
                만들기
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Make;
