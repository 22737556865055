import React,{useState,useEffect,useContext} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import * as DOMPurify from "dompurify";
import Loading from '../component/Loading.js';
import Nav from '../component/Nav.js';

import './Post.css';

function Post(){
	const {user} = useContext(UserContext);
	const history = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const {id}=useParams();
	const [post,setPost]=useState({});
	const [comment,setComment]=useState([]);
	const [commentForm,setCommentForm]=useState(null);
	useEffect(()=>{
		setIsLoading(true);
		axios.get(`/posts/${id}`, { withCredentials: true })
		.then(response => {
			setPost(response.data);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	},[]);
	useEffect(()=>{
		axios.get(`/comments/${id}`, { withCredentials: true })
		.then(response => {
			setComment(response.data);
			setIsLoading(false);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	},[]);

	const deleteposts=()=>{
		if(window.confirm('정말 삭제하시겠습니까?')){
			setIsLoading(true);
			axios.delete(`/posts/${id}`, { withCredentials: true})
			.then(response => {
				window.alert(response.data.alert);
				setIsLoading(false);
				history(`/board/${post.type}`);
			})
			.catch(error => {
				console.error('Error fetching user data:', error);
			});
		}
	}
	const createComment=()=>{
		if(commentForm!=null){
			if(user.isLogin){
				setIsLoading(true);
				axios.post('/comments',{post_id:id,user_id:user.userid,user_name:user.username,content:commentForm}, { withCredentials: true})
				.then(response => {
					axios.get(`/comments/${id}`, { withCredentials: true })
						.then(res => {
						setComment(res.data);
					})
						.catch(err => {
						console.error('Error fetching user data:', err);
					});
					window.alert(response.data.alert);
					setIsLoading(false);
					history(`/post/${id}`);
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
				});
			}
			else{
				window.alert('댓글은 로그인 후 이용할 수 있습니다.');
				history('/login');
			}
		}
	}
	const deletecomment=(idx)=>{
		if(window.confirm('정말 삭제하시겠습니까?')){
			setIsLoading(true);
			axios.delete(`/comments/${idx}`, { withCredentials: true})
			.then(response => {
				axios.get(`/comments/${id}`, { withCredentials: true })
						.then(res => {
						setComment(res.data);
					})
						.catch(err => {
						console.error('Error fetching user data:', err);
					});
				window.alert(response.data.alert);
				setIsLoading(false);
				history(`/post/${id}`);
			})
			.catch(error => {
				console.error('Error fetching user data:', error);
			});
		}
	}
	return(
		<div>
			{isLoading?<Loading/>:
			<div>
				 <Nav/>
				 <div className='pst'>
					 <div className='pst_ct'>
						 <div className='pst_tt'>{post.title}</div>
						 <div className='pst_detail'>
							 <div className='pst_usr'>{post.user_name}</div>
							 <div className='bar'>|</div>
							 <div className='pst_upt'>작성일자 : {post.updated_at}</div>
							 <div className='pst_upt'>댓글 : {comment.length}</div>
							 {user.isLogin && user.userid===post.user_id ?
								<div>
								  <button className='cmtsbm usbtn' onClick={()=>history(`/change/${id}`)}>수정</button>
								  <button className='cmtsbm usbtn' onClick={deleteposts}>삭제</button>
								</div>
							 :''}
						 </div>
						 <div className='dvd_bar'/>
						 <div className='content_bx'
							 dangerouslySetInnerHTML={{
								 __html: DOMPurify.sanitize(String(post.content)),
							 }}/>
						 {post.type==='1'?'':
						 <div>
						 <div className='pst_usr'>댓글</div>
						 <div className='dvd_bar'/>
						 <div>
							 <form onSubmit={createComment}>
								 <textarea placeholder='댓글 작성' onChange={(e)=>setCommentForm(e.target.value)} className='cmtform' required></textarea>
								 <button type='submit' className='cmtsbm'>등록</button>
							 </form>
						 </div>
						 <div className='cmt_bx'>
							 {comment.map(item=>
							 <div className='cmt'>
								<div className='cmt_user_name'>{item.user_id===post.user_id?'글쓴이':item.user_name}</div>
								<div className='cmt_content'>{item.content}</div>
								<div className='cmt_updated_at'>{item.created_at}</div>
								{user.isLogin && user.userid===item.user_id ? <div className='cmtdel' onClick={()=>deletecomment(item.id)}>삭제</div>:''}
							 </div>
							)}
						 </div>
						  </div>
						  }
					 </div>
				 </div>
			</div>
			}
		</div>);
}

export default Post;