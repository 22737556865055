import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./Solved.css";
import { PieChart, Pie, Cell,Radar,RadarChart,PolarAngleAxis,PolarGrid,PolarRadiusAxis,Tooltip } from 'recharts';
import Loading from "./Loading";
import CountUp from 'react-countup';

function Solved({ popVal, popupRef }) {
  const history=useNavigate();
  const [isLoading,setIsLoading]=useState(false);
  const [score,setScore]=useState(0);
  const [wrong,setWrong]=useState([]);
  const [columns,setColumns]=useState(10);
  const [radardata,setRadardata]=useState([]);
  const [mine,setMine]=useState([]);
  const [rows,setRows]=useState([]);
  const [piedata,setPiedata]=useState([]);
  const [prb,setPrb]=useState([]);
  const types = {
    "1": "수학 - 풀모",
    "2": "수학 - 하프",
    "3": "수학 - 킬러",
    "4": "수학 - 어삼쉬사",
    "5": "영어 - 하프",
    "6": "영어 - 빈순삽",
    "7": "영어 - 최고난도",
    "8": "영어 - 시간단축",
    "9": "국어 - 풀모",
    "10": "국어 - 매3비",
    "11": "국어 - 매3문",
    "12": "국어 - 매3화작",
    "13": "한국사 - 풀모",
  };
  const select = {
    m1: "수1",
    m2: "수2",
    cal: "미적",
    prob: "확통",
    vec: "기하",
    media: "언매",
    talk: "화작",
  };
  const categoryChoose=(val,types)=>{
    if(types>=1 && types<=4){
      const temp=[
        '지수와 로그',
        '지수함수 로그함수',
        '지수로그 부등식',
        '삼각법',
        '삼각함수',
        '사인법칙 코사인법칙',
        '등차수열 등비수열',
        '수열의 합',
        '수학적 귀납법',
        '함수의 극한',
        '수2 미분법',
        '수2 도함수 활용',
        '수2 적분법',
        '수2 정적분 활용',
        '수열의 극한',
        '미적 미분법',
        '미적 도함수 활용',
        '미적 적분법',
        '미적 정적분 활용',
        '경우의 수',
        '확률',
        '통계',
        '기하'
      ]
      return temp[Number(val)-1]
    }
    else if(types<=8 && types>=5){
      const temp={
        '18':'글의 목적',
        '19':'심경 변화',
        '20':'필자의 주장',
        '21':'밑줄의 의미',
        '22':'글의 요지',
        '23':'글의 주제',
        '24':'글의 제목',
        '25':'도표',
        '26':'내용 일치',
        '27':'포스터',
        '29':'어법',
        '30':'낱말의 쓰임',
        '31':'빈칸 2점',
        '33':'빈칸 3점',
        '35':'글의 흐름',
        '36':'순서',
        '37':'순서 3점',
        '38':'삽입',
        '39':'삽입 3점',
        '40':'긴글 요약',
        '41':'중간글',
        '43':'긴글'
      }
      return temp[val]
    }
    else if(types>=9 && types<=12){
      const temp=[
        '독서론',
        '인문/예술',
        '법/경제',
        '과학/기술',
        '독서 융합',
        '현대시',
        '고전시가',
        '현대소설',
        '고전산문',
        '극/수필',
        '문학 융합',
        '매체',
        '언어',
        '화법',
        '작문',
        '화법 작문 융합'
      ]
      return temp[Number(val)-1]
    }
    else if(types===13){
      const temp=[
        '선사시대',
        '~조선',
        '일제강점기',
        '근현대'
      ]
      return temp[Number(val)-1]
    }
    else{
      return 0;
    }
  }
  const COLORS = ['#00C49F', '#E0E0E0'];
  useEffect(()=>{
    const tmp=popVal.answer.split(',');
    setMine(tmp);
    const tmp2 = [
      { value: popVal.score },
      { value: popVal.problems.length - popVal.score}
    ];
    setPiedata(tmp2);
    const tmp3 = [];
    for (let i = 0; i < tmp.length; i += columns) {
      tmp3.push(tmp.slice(i, i + columns));
    }
    setRows(tmp3);

    axios.post('/solved',{id:popVal.id})
    .then(response=>{
      setPrb(response.data.solved.prb);
      var count=0;
      var categoryResults={};
      const wrtemp=[];
      response.data.solved.prb.forEach((item,idx)=>{
        const {category}=item;
        if (!categoryResults[category]) {
          categoryResults[category] = { correct: 0, total: 0 };
        }
        if(Number(item.answer)===Number(tmp[idx])){
          count+=item.score;
          categoryResults[category].correct += 1;
        }
        else{
          wrtemp.push(idx);
        }
        categoryResults[category].total+=1;
      })
      setWrong(wrtemp);
      const prt=[];
      if (!categoryResults || Object.keys(categoryResults).length === 0) {
        console.warn("categoryResults is empty or undefined.");
        return; // 빈 결과로 처리
      }
      Object.keys(categoryResults).forEach((category) => {
        const { correct, total } = categoryResults[category] || { correct: 0, total: 0 };
        const correctRate = total > 0 ? (correct / total) * 100 : 0;
        prt.push({
          category: categoryChoose(category,Number(popVal.types)), // 카테고리 이름
          A: correctRate.toFixed(1), // 정답률
          fullMark: 100, // 최대 점수
          correct:correct,
          total:total,
        });
      })
      setRadardata(prt);
      setScore(count);
    })
    .catch(error=>{
      console.log("error during get problem data ",error);
    })
  },[popVal]);

  const RegenaratePrb = async (prbjson) => {
    try {
      setIsLoading(true);
      const response = await fetch("/regenarateprb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prbjson),
      });
      if (response.ok) {
        if (response.headers.get("Content-Type") === "application/pdf") {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "problems.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
          history("/userinfo");
        } else {
          const data = await response.json();
          window.alert(data.alert);
          setIsLoading(false);
          history("/");
        }
      } else {
        history("/error");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const CorrectPrb=(key)=>{
    if(key.answer){
      if(window.confirm("다시 풀면 기존의 내 데이터가 변동될 수 있습니다.\n그래도 다시 푸시겠습니까?")){
        history(`/correct/${key.id}`);
      }
    }
    else{
      history(`/correct/${key.id}`);
    }
  }
  const deletePrb = async (id) => {
    if (window.confirm("정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다.")) {
      try {
        setIsLoading(true);
        const response = await fetch("/deleteprb", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        });
        if (response.ok) {
          window.alert("삭제 되었습니다.");
          setIsLoading(false);
          history("/userinfo");
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };
  useEffect(() => {
    const updateColumns = () => {
      setColumns(window.innerWidth <= 800 ? 5 : 10); // 너비에 따라 열 수 조정
    };

    updateColumns(); // 초기 열 수 설정
    window.addEventListener('resize', updateColumns); // 화면 크기 변경 감지
    return () => window.removeEventListener('resize', updateColumns); // 컴포넌트 해제 시 이벤트 제거
  }, []);
  return (
    <div className="pop_ctx">
      {isLoading?<Loading/>:
      <div className="pop_bxt" ref={popupRef}>
        <div className="bxt_bxt1">
          <div className="bxt1_l">
            <div className="pop_bxt_title">
              {types[popVal.types]}
              {popVal.types === "9" ||
              popVal.types === "12" ||
              (Number(popVal.types) <= 4 && Number(popVal.types) >= 1)
                ? `(${select[popVal.select_type]})`
                : ""}
            </div>
            <div className="bxt_time">{popVal.solved_time}</div>
            <div>
              {popVal.types==='5'?'28':popVal.types==='8'?'15':popVal.problems.length}
              문제
            </div>
            <div className="icon_ctt">
              <div className='icon_bxt' onClick={() => CorrectPrb(popVal)}>
                <img src='/img/correct.png' width='20px' alt="correct"/>
                <div className="icon_bxt_tt">다시채점</div>
              </div>
              <div className='icon_bxt' onClick={() => RegenaratePrb(popVal)}>
                <img src='/img/download.png' width='20px' alt="down"/>
                <div className="icon_bxt_tt">다운로드</div>
              </div>
              <div className='icon_bxt' onClick={() => deletePrb(popVal.id)}>
                <img src='/img/remove.png' width='20px' alt="delete"/>
                <div className="icon_bxt_tt">삭제</div>
              </div>
            </div>
          </div>
          <div className="bxt1_r">
            <div className="score_bxt">
              <CountUp start={0} end={score} duration={2.5} className="score_cnt"/>
              <div className="score_cnt">점</div>
            </div>
            <PieChart width={200} height={200}>
              <Pie
                data={piedata}
                startAngle={90} // Start at the top
                endAngle={450} // Fill clockwise to the set percentage
                innerRadius={70} // Radius settings for gauge effect
                outerRadius={80}
                dataKey="value"
              >
                {piedata.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text
                x={100}
                y={90}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={24}
                fontWeight="bold"
              >
                {`${popVal.score}/${popVal.types==='5'?'28':popVal.types==='8'?'15':popVal.problems.length}`}
              </text>
              <text
                x={100}
                y={120}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={16}
                fontWeight="bold"
              >
                맞춘 문제
              </text>
            </PieChart>
          </div>
        </div>
        <div className="bxt_bxt2">
          <div className="bxt2_tt">내 답</div>
        {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: 'flex'}}>
          {row.map((item, index) => (
            <div
              key={index}
              style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'center', width: '40px' }}
            >
              <div style={{ fontWeight: 'bold' }}>{rowIndex * columns + index + 1}번</div> {/* 항목 번호 */}
              <div>{item==='0'?'X':item}</div> {/* 입력한 데이터 */}
            </div>
          ))}
        </div>
      ))}
        </div>
        <div className="bxt_bxt3">
          <div className="bxt2_tt">카테고리별 정답률</div>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" width={600} height={200} data={radardata}>
            <PolarGrid />
            <Tooltip/>
            <PolarAngleAxis dataKey="category" fontSize={12}/>
            <PolarRadiusAxis />
            <Radar dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6}/>
        </RadarChart>
        <div className="radar_table">
        {radardata.map(item=>
          <div className="radar_t">
            <div style={{fontWeight:'bold',fontSize:'12px'}}>{item.category}</div>
            <div style={{fontSize:'12px'}}>{item.correct}/{item.total}</div>
            <div style={{fontSize:'16px',color:'rgba(255,0,0,0.8)'}}>{item.A}%</div>
          </div>
        )}
        </div>
        </div>
        <div className="bxt_bxt4">
          <div className="bxt2_tt">틀린 문제 정보</div>
          <div className="wrong_lst">
            <table>
              <tr>
                <th>문항번호</th>
                <th>출처</th>
                <th>정답률</th>
                <th>정답</th>
                <th>내 오답</th>
              </tr>
            {wrong.map((item)=>
              <tr className="wrlst_bx">
                <td style={{fontFamily:'Black han sans',fontSize:'20px'}}>{item+1}번</td>
                <td>{prb[item].description}</td>
                <td>{(prb[item].diff*100).toFixed(0)}%</td>
                <td style={{color:'#29CC44',fontFamily:'Black han sans',fontSize:'20px'}}>{prb[item]['answer']}</td>
                <td style={{color:'rgba(255,0,0,0.8)',fontFamily:'Black han sans',fontSize:'20px'}}>{mine[item]}</td>
              </tr>
            )}
            </table>
          </div>
        </div>
      </div>}
    </div>
  );
}
export default Solved;
