import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Correct.css";
import Nav from "../component/Nav";
import { UserContext } from "../UserProvider";
import Loading from "../component/Loading";
function Correct() {
  const { id } = useParams();
  const history = useNavigate();
  const [solvedlist, setSolvedlist] = useState({});
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(UserContext);
  const types = [
    "수학 - 풀모",
    "수학 - 하프",
    "수학 - 킬러",
    "수학 - 어삼쉬사",
    "영어 - 하프",
    "영어 - 빈순삽",
    "영어 - 최고난도",
    "영어 - 시간단축",
    "국어 - 풀모",
    "국어 - 매3비",
    "국어 - 매3문",
    "국어 - 매3화작",
    "한국사 - 풀모",
  ];
  const selects = {
    cal: "미적",
    prob: "확통",
    vec: "기하",
    m1: "수1",
    m2: "수2",
    media: "언매",
    talk: "화작",
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`/solved`, { id: id })
      .then((response) => {
        if(response.data.solved.userid!==user.userid){
          window.alert("회원 정보가 다릅니다. 확인 후 다시 시도해 주세요.")
          history('/')
        }
        else{
          setSolvedlist(response.data.solved);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching solved data:", error);
      });
  }, []);
  const submitCrt = () => {
    var missing = "";
    var temp = formData;
    var score = 0;
    var fail = "";
    const categorys=[];
    const diffs=[];
    const correct=[];
    solvedlist.prb.forEach((item, idx) => {
      if (!formData[idx]) {
        missing += `${idx + 1}번, `;
        temp[idx] = 0;
        fail += `${idx + 1}번, `;
        correct.push(0);
      } else if (Number(formData[idx]) === Number(item["answer"])) {
        score += 1;
        correct.push(1);
      } else if(item['category']===41 || item['category']===43){
        const a=formData[idx].split(' ');
        const b=item['answer'].split(',');
        var check=0;
        a.forEach((cm,idr)=>{
          if(Number(cm)===b[idr]){
            score+=1;
            check+=1;
          }
        })
        if(check===3){
          correct.push(1);
        }
        else{
          correct.push(0);
        }
      }
        else {
        fail += `${idx + 1}번, `;
        correct.push(0);
      }
      categorys.push(item["category"]);
      diffs.push(item["diff"]);
    });
    if (missing !== "") {
      if (
        !window.confirm(
          `누락된 문제가 있습니다.\n그래도 채점하시겠습니까?\n${missing}`
        )
      ) {
        return 0;
      }
    }
    const valueList = Object.keys(temp)
      .sort((a, b) => a - b)
      .map((key) => temp[key]);
    // 값들을 문자열로 변환하여 ','로 연결
    const answer = valueList.join(",");
    const cate=categorys.join(",");
    const df=diffs.join(",");
    const crt=correct.join(',');
    axios
      .post("/correct", { id: id, answer: answer,correct:crt,category:cate,diff:df, score: score })
      .then((response) => {
        if (fail === "") {
          window.alert(`채점 완료했습니다.\n 모두 맞추셨습니다. 축하합니다!`);
        } else {
          window.alert(
            `채점 완료했습니다.\n점수 : ${score}/${
              solvedlist.prb.length
            }\n 틀린 문제 : ${fail}`
          );
        }
        history("/userinfo");
      })
      .catch((error) => {
        window.alert("채점 중 오류가 발생했습니다. 다시 시도해 주세요.");
        console.error("Error fetching correct data:", error);
      });
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="crt_ct">
          <Nav />
          <div className="crt_fm">
            <div className="crt_intro">
              <div className="crt_title">
                {types[Number(solvedlist["types"])]}
                {(Number(solvedlist["types"]) >= 1 &&
                  Number(solvedlist["types"]) <= 4) ||
                Number(solvedlist["types"]) === 9 ||
                Number(solvedlist["types"]) === 12
                  ? `(${selects[solvedlist["select_type"]]})`
                  : ""}
              </div>
            </div>
            <div className="crt_chk">
              {solvedlist["prb"].map(
                (item, idx) =>
                  item.type === 0 || Number(item.category)===41 || Number(item.category)===43 ? (
                    <div className="chk_nmb">
                      <div className="nmb_title">{idx + 1}</div>
                      <input
                        type="text"
                        pattern="[0-9 ]*"
                        title="숫자와 빈칸만 입력 가능합니다."
                        oninvalid="this.setCustomValidity('숫자와 빈칸만 입력 가능합니다.')"
                        oninput="this.setCustomValidity('')"
                        className="nmb_bx"
                        placeholder={(Number(item.category)===41 || Number(item.category)===43)?'문제는 빈칸 1개로 구분해 주세요.ex) 1 2 1':''}
                        onChange={(e) =>
                          setFormData({ ...formData, [idx]: e.target.value })
                        }
                      />
                    </div>
                  ) : (
                    <div className="chk_nmb">
                      <div className="nmb_title">{idx + 1}</div>
                      <div className="nmb_btn">
                        <div
                          className="btn_des"
                          onClick={() => setFormData({ ...formData, [idx]: 1 })}
                          style={{
                            background: formData[idx] === 1 ? "#333" : "",
                          }}
                        >
                          1
                        </div>
                        <div
                          className="btn_des"
                          onClick={() => setFormData({ ...formData, [idx]: 2 })}
                          style={{
                            background: formData[idx] === 2 ? "#333" : "",
                          }}
                        >
                          2
                        </div>
                        <div
                          className="btn_des"
                          onClick={() => setFormData({ ...formData, [idx]: 3 })}
                          style={{
                            background: formData[idx] === 3 ? "#333" : "",
                          }}
                        >
                          3
                        </div>
                        <div
                          className="btn_des"
                          onClick={() => setFormData({ ...formData, [idx]: 4 })}
                          style={{
                            background: formData[idx] === 4 ? "#333" : "",
                          }}
                        >
                          4
                        </div>
                        <div
                          className="btn_des"
                          onClick={() => setFormData({ ...formData, [idx]: 5 })}
                          style={{
                            background: formData[idx] === 5 ? "#333" : "",
                          }}
                        >
                          5
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="crt_btn">
              <div className="crt_btn_clk" onClick={submitCrt}>
                채점하기
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Correct;
